<template>
  <div class="wild">
    <section class="hero text-white">
      <template>
        <hooper
          class="h-100"
          :wheelControl="false"
          :autoPlay="true"
          :playSpeed="5000"
          :transition="1000"
          :infiniteScroll="true"
          :hoverPause="false"
        >
          <slide class="hero-slide-1">
            <div class="px-5 h-100 container d-flex align-items-center">
              <div class="">
                <h1 class="display-1 fw-bold v-text">We Do Wild</h1>
                <h3 class="v-text pb-3">
                  The Ultimate Pilanesberg National Park Experience
                </h3>
                <a
                  href="https://wa.me/+27823792293"
                  target="_blank"
                  class="
                    btn btn-lg btn-secondary
                    fw-bold
                    border-white
                    bg-white
                    text-dark
                    p
                  "
                  >Book Your Spot Now</a
                >
              </div>
            </div>
          </slide>
          <slide class="hero-slide-2">
            <div class="px-5 h-100 container d-flex align-items-center">
              <div class="">
                <h1 class="display-1 fw-bold v-text">We Do Wild</h1>
                <h3 class="v-text pb-3">
                  The Ultimate Botlierskop Private Game Reserve Experience
                </h3>
                <a
                  href="https://wa.me/+27823792293"
                  target="_blank"
                  class="
                    btn btn-lg btn-secondary
                    fw-bold
                    border-white
                    bg-white
                    text-dark
                    p
                  "
                  >Book Your Spot Now</a
                >
              </div>
            </div>
          </slide>
          <slide class="hero-slide-3">
            <div class="px-5 h-100 container d-flex align-items-center">
              <div class="">
                <h1 class="display-1 fw-bold v-text">We Do Wild</h1>
                <h3 class="v-text pb-3">
                  The Ultimate Aquila Private Game Reserve Experience
                </h3>
                <a
                  href="https://wa.me/+27823792293"
                  target="_blank"
                  class="
                    btn btn-lg btn-secondary
                    fw-bold
                    border-white
                    bg-white
                    text-dark
                    p
                  "
                  >Book Your Spot Now</a
                >
              </div>
            </div>
          </slide>
          <slide class="hero-slide-4">
            <div class="px-5 h-100 container d-flex align-items-center">
              <div class="">
                <h1 class="display-1 fw-bold v-text">We Do Adrenaline</h1>
                <h3 class="v-text pb-3">The Ultimate Skydiving Experience</h3>
                <a
                  href="https://wa.me/+27823792293"
                  target="_blank"
                  class="
                    btn btn-lg btn-secondary
                    fw-bold
                    border-white
                    bg-white
                    text-dark
                    p
                  "
                  >Book Your Spot Now</a
                >
              </div>
            </div>
          </slide>
          <hooper-navigation slot="hooper-addons"></hooper-navigation>
          <hooper-pagination slot="hooper-addons"></hooper-pagination>
        </hooper>
      </template>
    </section>

    <main class="container">
      <div id="kruger" class="pt-5"><Kruger class="pt-3"></Kruger></div>

      <div id="pilanesberg" class="pt-5">
        <Pilanesberg class="pt-5"></Pilanesberg>
      </div>

      <div id="botlierskop" class="pt-5">
        <Botlierskop class="pt-5"></Botlierskop>
      </div>

      <div id="aquila" class="pt-5"><Aquila class="pt-5"></Aquila></div>
    </main>
  </div>
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";

export default {
  components: {
    Hooper,
    Slide,
    HooperNavigation,
  },
};
</script>
