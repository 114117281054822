<template>
  <div class="para">
    <section class="hero text-white d-flex align-items-center">
      <div class="px-5 container">
        <h1 class="display-1 fw-bold v-text">We Do Adrenaline</h1>
        <h3 class="v-text pb-3">The Ultimate Paragliding Experience</h3>
        <a
          href="#"
          class="
            btn btn-lg btn-secondary
            fw-bold
            border-white
            bg-white
            text-dark
            p
          "
          >Book Your Spot Now</a
        >
      </div>
    </section>

    <main class="container mt-5">
      <section class="col-12 mt-lrg mb-lrg">
        <div class="row">
          <div class="col-md-6 section-header">
            <h2 class="v-text">Paragliding</h2>
            <p>
              Your journey begins from the landmarks of Signal Hill or Lions
              Head, both popular tourist destinations from which to enjoy the
              sun setting over city or stretching your legs on a hike. The views
              are a site to be reckoned with. Brace yourself as you run for the
              edge of the mountain. But don’t worry - soon the wind will catch
              the parachute and send you soaring into the air. As your heart
              races with excitement, spare a moment to take in the breath-taking
              views of Table Mountain and pristine beaches surrounded by the
              valley below.
            </p>
          </div>
          <div class="col-md-6 image-container">
            <img src="../assets/para-1.webp" class="img-fluid img-1" alt="" />
            <img src="../assets/para-2.webp" class="img-fluid img-2" alt="" />
            <img src="../assets/para-3.webp" class="img-fluid img-3" alt="" />
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>
