import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Adrenaline from "../views/Adrenaline.vue";
import Wild from "../views/Wild.vue";
import Tours from "../views/Tours.vue";
import Combo from "../views/Combo.vue";
import Contact from "../views/Contact.vue";
import SharkCage from "../views/SharkCage.vue";
import BungeeJumping from "../views/BungeeJumping.vue";
import Paragliding from "../views/Paragliding.vue";
import QuadBiking from "../views/QuadBiking.vue";
import SandBoarding from "../views/SandBoarding.vue";
import Skydiving from "../views/Skydiving.vue";
import Kruger from "../views/Kruger.vue";
import Botlierskop from "../views/Botlierskop.vue";
import Pilanesberg from "../views/Pilanesberg.vue";
import Aquila from "../views/Aquila.vue";
import VueScrollTo from "vue-scrollto";


Vue.use(VueScrollTo);
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/bungee",
    name: "BungeeJumping",
    component: BungeeJumping,
  },
  {
    path: "/paragliding",
    name: "Paragliding",
    component: Paragliding,
  },
  {
    path: "/quadbiking",
    name: "QuadBiking",
    component: QuadBiking,
  },
  {
    path: "/sandboarding",
    name: "SandBoarding",
    component: SandBoarding,
  },
  {
    path: "/sharkcage",
    name: "SharkCage",
    component: SharkCage,
  },
  {
    path: "/skydiving",
    name: "Skydiving",
    component: Skydiving,
  },
  {
    path: "/kruger",
    name: "Kruger",
    component: Kruger,
  },
  {
    path: "/aquila",
    name: "Aquila",
    component: Aquila,
  },
  {
    path: "/botlierskop",
    name: "Botlierskop",
    component: Botlierskop,
  },
  {
    path: "/pilanesberg",
    name: "Pilanesberg",
    component: Pilanesberg,
  },
  {
    path: "/adrenaline",
    name: "Adrenaline",
    component: Adrenaline,
  },

  {
    path: "/tours",
    name: "Tours",
    component: Tours,
  },
  {
    path: "/safari",
    name: "Wild",
    component: Wild,
  },
  {
    path: "/safari",
    name: "Wild",
    component: Wild,
  },
  {
    path: "/combo",
    name: "Combo",
    component: Combo,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
   scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
         if (to.hash) {
            return { selector: to.hash };
        }
    return { x: 0, y: 0 }
  },
});

export default router;
