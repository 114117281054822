import Vue from "vue";
import App from "./App.vue";
import router from "./router";



Vue.config.productionTip = false;

Vue.component("Header", require("./components/Header.vue").default);
Vue.component("Footer", require("./components/Footer.vue").default);
Vue.component("Quad", require("./components/Quad.vue").default);
Vue.component("Bungee", require("./components/Bungee.vue").default);
Vue.component("Para", require("./components/Para.vue").default);
Vue.component("Shark", require("./components/Shark.vue").default);
Vue.component("Sky", require("./components/Sky.vue").default);
Vue.component("Sand", require("./components/Sand.vue").default);
Vue.component("Aquila", require("./components/Aquila.vue").default);
Vue.component("Botlierskop", require("./components/Botlierskop.vue").default);
Vue.component("Pilanesberg", require("./components/Pilanesberg.vue").default);
Vue.component("Kruger", require("./components/Kruger.vue").default);
Vue.component("Sand", require("./components/Sand.vue").default);
Vue.component("Wine", require("./components/Wine.vue").default);
Vue.component("GardenRouteTour", require("./components/GardenRouteTour.vue").default);
Vue.component("Various", require("./components/Various.vue").default);
Vue.component("CapeToKruger", require("./components/CapeToKruger.vue").default);
Vue.component("Extreme", require("./components/Extreme.vue").default);
Vue.component("SharkCage", require("./components/SharkCage.vue").default);
Vue.component("WineSafari", require("./components/WineSafari.vue").default);
Vue.component("SandExperience", require("./components/SandExperience.vue").default);
Vue.component("Craft", require("./components/Craft.vue").default);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
