<template>
  <div>
    <section class="col-12 mb-5">
      <div class="row">
        <div class="col-lg-6 d-none d-lg-block order-1 order-lg-0">
          <div class="image-container d-flex justify-content-center">
            <img
              src="../assets/gin-1.webp"
              class="img-fluid left-img-1"
              alt=""
            />
            <img
              src="../assets/gin-3.webp"
              class="img-fluid left-img-2"
              alt=""
            />
            <img
              src="../assets/gin-2.webp"
              class="img-fluid left-img-3"
              alt=""
            />
          </div>
        </div>
        <div class="col-12 d-lg-none d-block order-1 order-lg-0">
          <div class="d-flex justify-content-center">
            <div class="row pt-5">
              <div class="col-4">
                <img src="../assets/gin-1.webp" class="img-fluid" alt="" />
              </div>
              <div class="col-4">
                <img src="../assets/gin-3.webp" class="img-fluid" alt="" />
              </div>
              <div class="col-4">
                <img src="../assets/gin-2.webp" class="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-12 order-0 order-lg-1">
          <h2 class="v-text">Craft Gin & Beer Tour</h2>
          <p>
            Especially crafted for beer and gin lovers, this tour is appeals to
            all your senses and is filled with flavours! You will spend the day
            in Stellenbosch and Franschhoek, which is not only wine country, but
            also home to some of the best breweries and distilleries. The tour
            explores three artisanal gin distilleries, three craft beer
            breweries, and event has a coffee and biltong tasting experience.
          </p>
          <a
            href="https://wa.me/+27823792293"
            target="_blank"
            class="btn btn-primary text-white p"
            >Book Now</a
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
