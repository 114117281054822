<template>
  <div class="combo">
    <section class="hero text-white">
      <template>
        <hooper
          class="h-100"
          :wheelControl="false"
          :autoPlay="true"
          :playSpeed="5000"
          :transition="1000"
          :infiniteScroll="true"
          :hoverPause="false"
        >
          <slide class="hero-slide-1">
            <div class="px-5 h-100 container d-flex align-items-center">
              <div class="">
                <h1 class="display-1 fw-bold v-text">We Do Extreme Tours</h1>
                <h3 class="v-text pb-3">
                  The Ultimate Extreme Tour Expperience
                </h3>
                <a
                  href="https://wa.me/+27823792293"
                  class="
                    btn btn-lg btn-secondary
                    fw-bold
                    border-white
                    bg-white
                    text-dark
                    p
                  "
                  >Book Your Spot Now</a
                >
              </div>
            </div>
          </slide>
          <slide class="hero-slide-2">
            <div class="px-5 h-100 container d-flex align-items-center">
              <div class="">
                <h1 class="display-1 fw-bold v-text">We Do Shark Cage Tours</h1>
                <h3 class="v-text pb-3">
                  The Ultimate Great White Shark Cage Diving Experience
                </h3>
                <a
                  href="https://wa.me/+27823792293"
                  class="
                    btn btn-lg btn-secondary
                    fw-bold
                    border-white
                    bg-white
                    text-dark
                    p
                  "
                  >Book Your Spot Now</a
                >
              </div>
            </div>
          </slide>
          <slide class="hero-slide-3">
            <div class="px-5 h-100 container d-flex align-items-center">
              <div class="">
                <h1 class="display-1 fw-bold v-text">
                  We Do Wine Safari Tours
                </h1>
                <h3 class="v-text pb-3">The Ultimate Wine Safari Experience</h3>
                <a
                  href="https://wa.me/+27823792293"
                  class="
                    btn btn-lg btn-secondary
                    fw-bold
                    border-white
                    bg-white
                    text-dark
                    p
                  "
                  >Book Your Spot Now</a
                >
              </div>
            </div>
          </slide>

          <hooper-navigation slot="hooper-addons"></hooper-navigation>
          <hooper-pagination slot="hooper-addons"></hooper-pagination>
        </hooper>
      </template>
    </section>

    <main class="container">
      <p>
        These tours are specifically designed to meet the needs of our customers
        looking for an adventure, a chilled outing, or to sit back and enjoy
        Nature at its best. We believe if you can dream it we will make it
        happen. To whet your palate, take a look at these three great package
        options:
      </p>
      <ul class="list-unstyled">
        <li>
          <p>
            <i class="fas fa-certificate text-red"></i> The Extreme Tour for
            adrenalin junkies <br /><router-link
              class="btn btn-primary text-white p ms-5"
              :to="'/combo#extreme'"
              >More Info</router-link
            >
          </p>
        </li>
        <li>
          <p>
            <i class="fas fa-certificate text-red"></i> The Shark Cage Safari
            for both adrenaline and nature<br /><router-link
              class="btn btn-primary text-white p ms-5"
              :to="'/combo#shark'"
              >More Info</router-link
            >
          </p>
        </li>
        <li>
          <p>
            <i class="fas fa-certificate text-red"></i> The Sand Experience for
            those wishing to remain on terra firma.<br /><router-link
              class="btn btn-primary text-white p ms-5"
              :to="'/combo#sandex'"
              >More Info</router-link
            >
          </p>
        </li>
      </ul>
      <h5 class="pt-3"><u>Alternative Tour Options:</u></h5>
      <p id="extreme">
        If you looking for bespoke, please contact us and we will collaborate
        with you to plan something spectacular.
      </p>
      <Extreme class="pt-4"></Extreme>
      <SharkCage id="shark" class="pt-4"></SharkCage>
      <SandExperience id="sandex" class="pt-4"></SandExperience>
    </main>
  </div>
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";

export default {
  components: {
    Hooper,
    Slide,
    HooperNavigation,
  },
};
</script>
