<template>
  <div class="bg-blue py-5">
    <div class="container p-4 w-100">
      <p class="text-center text-white">
        ADVENTURE CAPE TOWN.CO.ZA © {{ new Date().getFullYear() }}
      </p>
      <br />
      <p class="text-center text-white">
        <a
          href="mailto.info@adventure-capetown.com"
          class="
            text-center text-white text-uppercase text-decoration-none
            pe-5
          "
          ><i class="far fa-envelope"></i> info@adventure-capetown.com</a
        >

        <a
          href="tel.0823792293"
          class="text-center text-white text-decoration-none pe-5"
          ><i class="fas fa-phone-alt"></i> 082 379 2293</a
        >

        <a
          href="https://www.facebook.com/tourcapetown?ref=br_tf"
          class="text-center text-white text-decoration-none"
          ><i class="fab fa-facebook-f pe-3"> </i
        ></a>

        <a
          href="https://www.instagram.com/adventurecapetown"
          class="text-center text-white text-decoration-none"
          ><i class="fab fa-instagram pe-3"> </i
        ></a>

        <a
          href="https://www.tripadvisor.co.za/Attraction_Review-g312659-d8298372-Reviews-Adventure_Cape_Town-Cape_Town_Central_Western_Cape.html"
          class="text-center text-white text-uppercase text-decoration-none"
          target="_blank"
          >Trip Advisor</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      date: null,
    };
  },
};
</script>
