<template>
  <div class="tours">
    <section class="hero text-white w-100">
      <template>
        <hooper
          class="h-100 w-100"
          :wheelControl="false"
          :autoPlay="true"
          :playSpeed="5000"
          :transition="1000"
          :infiniteScroll="true"
          :hoverPause="false"
        >
          <slide class="hero-slide-1">
            <div class="px-5 h-100 container d-flex align-items-center">
              <div class="">
                <h1 class="display-1 fw-bold v-text">We Do Tours</h1>
                <h3 class="v-text pb-3">The Ultimate Wine Tour</h3>
                <a
                  href="https://wa.me/+27823792293"
                  class="
                    btn btn-lg btn-secondary
                    fw-bold
                    border-white
                    bg-white
                    text-dark
                    p
                  "
                  >Book Your Spot Now</a
                >
              </div>
            </div>
          </slide>
          <slide class="hero-slide-2">
            <div class="px-5 h-100 container d-flex align-items-center">
              <div class="">
                <h1 class="display-1 fw-bold v-text">We Do Tours</h1>
                <h3 class="v-text pb-3">The Ultimate Garden Route Tour</h3>
                <a
                  href="https://wa.me/+27823792293"
                  class="
                    btn btn-lg btn-secondary
                    fw-bold
                    border-white
                    bg-white
                    text-dark
                    p
                  "
                  >Book Your Spot Now</a
                >
              </div>
            </div>
          </slide>
          <slide class="hero-slide-3">
            <div class="px-5 h-100 container d-flex align-items-center">
              <div class="">
                <h1 class="display-1 fw-bold v-text">We Do Tours</h1>
                <h3 class="v-text pb-3">
                  The Ultimate Cape to Kruger Experience
                </h3>
                <a
                  href="#"
                  class="
                    btn btn-lg btn-secondary
                    fw-bold
                    border-white
                    bg-white
                    text-dark
                    p
                  "
                  >Book Your Spot Now</a
                >
              </div>
            </div>
          </slide>
          <slide class="hero-slide-4">
            <div class="px-5 h-100 container d-flex align-items-center">
              <div class="">
                <h1 class="display-1 fw-bold v-text">We Do Tours</h1>
                <h3 class="v-text pb-3">
                  The Ultimate Guided Day Trips To Various Historical Landmarks
                </h3>
                <a
                  href="https://wa.me/+27823792293"
                  class="
                    btn btn-lg btn-secondary
                    fw-bold
                    border-white
                    bg-white
                    text-dark
                    p
                  "
                  >Book Your Spot Now</a
                >
              </div>
            </div>
          </slide>
          <hooper-navigation slot="hooper-addons"></hooper-navigation>
          <hooper-pagination slot="hooper-addons"></hooper-pagination>
        </hooper>
      </template>
    </section>

    <main class="container">
      <div class="row w-100">
        <div id="wine" class="col-md-12 mt-5"></div>
        <Wine></Wine>
        <div id="gin" class="col-md-12 mt-5"></div>
        <Craft></Craft>
        <div id="garden" class="col-md-12 mt-5"></div>
        <GardenRouteTour></GardenRouteTour>
        <div id="kruger" class="col-md-12 mt-5"></div>
        <CapeToKruger></CapeToKruger>
        <div id="var" class="col-md-12 mt-5"></div>
        <Various></Various>
      </div>
    </main>
  </div>
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";

export default {
  components: {
    Hooper,
    Slide,
    HooperNavigation,
  },
};
</script>
