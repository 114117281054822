<template>
  <div class="adrenaline">
    <section class="hero text-white" id="quad">
      <template>
        <hooper
          class="h-100"
          :wheelControl="false"
          :autoPlay="true"
          :playSpeed="5000"
          :transition="1000"
          :infiniteScroll="true"
          :hoverPause="false"
        >
          <slide class="hero-slide-1">
            <div class="px-5 h-100 container d-flex align-items-center">
              <div class="">
                <h1 class="display-1 fw-bold v-text">We Do Adrenaline</h1>
                <h3 class="v-text pb-3">The Ultimate Quad Biking Experience</h3>
                <a
                  href="https://wa.me/+27823792293"
                  class="
                    btn btn-lg btn-secondary
                    fw-bold
                    border-white
                    bg-white
                    text-dark
                    p
                  "
                  >Book Your Spot Now</a
                >
              </div>
            </div>
          </slide>
          <slide class="hero-slide-2">
            <div class="px-5 h-100 container d-flex align-items-center">
              <div class="">
                <h1 class="display-1 fw-bold v-text">We Do Adrenaline</h1>
                <h3 class="v-text pb-3">
                  The Ultimate Sandboarding Experience
                </h3>
                <a
                  href="https://wa.me/+27823792293"
                  class="
                    btn btn-lg btn-secondary
                    fw-bold
                    border-white
                    bg-white
                    text-dark
                    p
                  "
                  >Book Your Spot Now</a
                >
              </div>
            </div>
          </slide>
          <slide class="hero-slide-3">
            <div class="px-5 h-100 container d-flex align-items-center">
              <div class="">
                <h1 class="display-1 fw-bold v-text">We Do Adrenaline</h1>
                <h3 class="v-text pb-3">The Ultimate Paragliding Experience</h3>
                <a
                  href="https://wa.me/+27823792293"
                  class="
                    btn btn-lg btn-secondary
                    fw-bold
                    border-white
                    bg-white
                    text-dark
                    p
                  "
                  >Book Your Spot Now</a
                >
              </div>
            </div>
          </slide>
          <slide class="hero-slide-4">
            <div class="px-5 h-100 container d-flex align-items-center">
              <div class="">
                <h1 class="display-1 fw-bold v-text">We Do Adrenaline</h1>
                <h3 class="v-text pb-3">The Ultimate Skydiving Experience</h3>
                <a
                  href="https://wa.me/+27823792293"
                  class="
                    btn btn-lg btn-secondary
                    fw-bold
                    border-white
                    bg-white
                    text-dark
                    p
                  "
                  >Book Your Spot Now</a
                >
              </div>
            </div>
          </slide>
          <slide class="hero-slide-5">
            <div class="px-5 h-100 container d-flex align-items-center">
              <div class="">
                <h1 class="display-1 fw-bold v-text">We Do Adrenaline</h1>
                <h3 class="v-text pb-3">
                  The Ultimate Bungee Jumping Experience
                </h3>
                <a
                  href="https://wa.me/+27823792293"
                  class="
                    btn btn-lg btn-secondary
                    fw-bold
                    border-white
                    bg-white
                    text-dark
                    p
                  "
                  >Book Your Spot Now</a
                >
              </div>
            </div>
          </slide>
          <slide class="hero-slide-6">
            <div class="px-5 h-100 container d-flex align-items-center">
              <div class="">
                <h1 class="display-1 fw-bold v-text">We Do Adrenaline</h1>
                <h3 class="v-text pb-3">
                  The Ultimate Shark Cage Diving Experience
                </h3>
                <a
                  href="https://wa.me/+27823792293"
                  class="
                    btn btn-lg btn-secondary
                    fw-bold
                    border-white
                    bg-white
                    text-dark
                    p
                  "
                  >Book Your Spot Now</a
                >
              </div>
            </div>
          </slide>

          ...
          <hooper-navigation slot="hooper-addons"></hooper-navigation>
          <hooper-pagination slot="hooper-addons"></hooper-pagination>
        </hooper>
      </template>
    </section>

    <main class="container">
      <Quad></Quad>
      <div id="sand" class="pt-5"></div>
      <Sand></Sand>
      <div id="para" class="pt-5"></div>
      <Para></Para>
      <div id="sky" class="pt-5"></div>
      <Sky></Sky>
      <div id="bungee" class="pt-5"></div>
      <Bungee></Bungee>
      <div id="shark" class="pt-5"></div>
      <Shark></Shark>
    </main>
  </div>
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";

export default {
  components: {
    Hooper,
    Slide,
    HooperNavigation,
  },
};
</script>
