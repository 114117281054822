<template>
  <div class="bungee">
    <section class="hero text-white d-flex align-items-center">
      <div class="px-5 container">
        <h1 class="display-1 fw-bold v-text">We Do Adrenaline</h1>
        <h3 class="v-text pb-3">The Ultimate Bungee Jumping Experience</h3>
        <a
          href="#"
          class="
            btn btn-lg btn-secondary
            fw-bold
            border-white
            bg-white
            text-dark
            p
          "
          >Book Your Spot Now</a
        >
      </div>
    </section>

    <main class="container mt-5">
      <section class="col-12 mt-lrg mb-lrg">
        <div class="row">
          <div class="col-md-6 section-header">
            <h2 class="v-text">Bungee Jumping</h2>
            <p>
              Bloukrans in Storms River is where you will find the ‘highest
              bungee from a bridge’ in the world. The view from Africa’s highest
              bridge captures all the majesty and tranquillity of the Bloukrans
              River valley. But there is nothing tranquil about throwing
              yourself more than 200 metres down towards the river below. This
              will be the most amazing experience of your life!
            </p>
            <p>
              The Bloukrans Bungy uses pendulum bungee technology to ensure the
              smoothest, most comfortable bungee jump possible. Jumpers are
              secured in a full-body harness combined with an ankle connection
            </p>
          </div>
          <div class="col-md-6 image-container">
            <img src="../assets/bungee-1.webp" class="img-fluid img-1" alt="" />
            <img src="../assets/bungee-2.webp" class="img-fluid img-2" alt="" />
            <img src="../assets/bungee-3.webp" class="img-fluid img-3" alt="" />
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>
