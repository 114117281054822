<template>
  <div class="kruger">
    <section class="hero text-white d-flex align-items-center">
      <div class="px-5 container">
        <h1 class="display-1 fw-bold v-text">We Do Wild</h1>
        <h3 class="v-text pb-3">Contact</h3>
        <a
          href="#"
          class="
            btn btn-lg btn-secondary
            fw-bold
            border-white
            bg-white
            text-dark
            p
          "
          >Book Your Spot Now</a
        >
      </div>
    </section>

    <main class="container mt-5 py-5">
      <form
        class="shake col-8 offset-2"
        role="form"
        method="post"
        id="contactForm"
        name="contact-form"
        data-toggle="validator"
      >
        <!-- Name -->
        <div class="form-group label-floating">
          <label class="control-label" for="name">Name</label>
          <input
            class="form-control"
            id="name"
            type="text"
            name="name"
            required
            data-error="Please enter your name"
          />
          <div class="help-block with-errors"></div>
        </div>
        <!-- email -->
        <div class="form-group label-floating">
          <label class="control-label" for="email">Email</label>
          <input
            class="form-control"
            id="email"
            type="email"
            name="email"
            required
            data-error="Please enter your Email"
          />
          <div class="help-block with-errors"></div>
        </div>
        <!-- Subject -->
        <div class="form-group label-floating">
          <label class="control-label">Subject</label>
          <input
            class="form-control"
            id="msg_subject"
            type="text"
            name="subject"
            required
            data-error="Please enter your message subject"
          />
          <div class="help-block with-errors"></div>
        </div>
        <!-- Message -->
        <div class="form-group label-floating">
          <label for="message" class="control-label">Message</label>
          <textarea
            class="form-control"
            rows="3"
            id="message"
            name="message"
            required
            data-error="Write your message"
          ></textarea>
          <div class="help-block with-errors"></div>
        </div>
        <!-- Form Submit -->
        <div class="form-submit mt-5">
          <button class="btn btn-blue p ms-5" type="submit" id="form-submit">
            <i class="material-icons mdi mdi-message-outline"></i> Send Message
          </button>
          <div id="msgSubmit" class="h3 text-center hidden"></div>
          <div class="clearfix"></div>
        </div>
      </form>
    </main>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>
<style>
.form-control,
.form-group .form-control {
  border: 0;
  background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#009688),
      to(#009688)
    ),
    -webkit-gradient(linear, left top, left bottom, from(#d2d2d2), to(#d2d2d2));
  background-image: -webkit-linear-gradient(#009688, #009688),
    -webkit-linear-gradient(#d2d2d2, #d2d2d2);
  background-image: -o-linear-gradient(#009688, #009688),
    -o-linear-gradient(#d2d2d2, #d2d2d2);
  background-image: linear-gradient(#009688, #009688),
    linear-gradient(#d2d2d2, #d2d2d2);
  -webkit-background-size: 0 2px, 100% 1px;
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center -webkit-calc(100% - 1px);
  background-position: center bottom, center calc(100% - 1px);
  background-color: rgba(0, 0, 0, 0);
  -webkit-transition: background 0s ease-out;
  -o-transition: background 0s ease-out;
  transition: background 0s ease-out;
  float: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  font-size: 1.8rem;
}

.form-control::-moz-placeholder,
.form-group .form-control::-moz-placeholder {
  color: #bdbdbd;
  font-weight: 400;
}

.form-control:-ms-input-placeholder,
.form-group .form-control:-ms-input-placeholder {
  color: #bdbdbd;
  font-weight: 400;
}

.form-control::-webkit-input-placeholder,
.form-group .form-control::-webkit-input-placeholder {
  color: #bdbdbd;
  font-weight: 400;
}

.form-control[disabled],
.form-control[readonly],
.form-group .form-control[disabled],
.form-group .form-control[readonly],
fieldset[disabled] .form-control,
fieldset[disabled] .form-group .form-control {
  background-color: rgba(0, 0, 0, 0);
}

.form-control[disabled],
.form-group .form-control[disabled],
fieldset[disabled] .form-control,
fieldset[disabled] .form-group .form-control {
  background-image: none;
  border-bottom: 1px dotted #d2d2d2;
}

.form-group {
  position: relative;
}

.form-group.label-floating label.control-label,
.form-group.label-placeholder label.control-label,
.form-group.label-static label.control-label {
  position: absolute;
  pointer-events: none;
  -webkit-transition: 0.3s ease all;
  -o-transition: 0.3s ease all;
  transition: 0.3s ease all;
}

.form-group.label-floating label.control-label {
  will-change: left, top, contents;
}

.form-group.label-placeholder:not(.is-empty) label.control-label {
  display: none;
}

.form-group .help-block {
  position: absolute;
  display: none;
}

.form-group.is-focused .form-control {
  outline: 0;
  background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#009688),
      to(#009688)
    ),
    -webkit-gradient(linear, left top, left bottom, from(#d2d2d2), to(#d2d2d2));
  background-image: -webkit-linear-gradient(#009688, #009688),
    -webkit-linear-gradient(#d2d2d2, #d2d2d2);
  background-image: -o-linear-gradient(#009688, #009688),
    -o-linear-gradient(#d2d2d2, #d2d2d2);
  background-image: linear-gradient(#009688, #009688),
    linear-gradient(#d2d2d2, #d2d2d2);
  -webkit-background-size: 100% 2px, 100% 1px;
  background-size: 100% 2px, 100% 1px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.form-group.is-focused .form-control .material-input:after {
  background-color: #009688;
}

.form-group.is-focused label,
.form-group.is-focused label.control-label {
  color: #009688;
}

.form-group.is-focused.label-placeholder label,
.form-group.is-focused.label-placeholder label.control-label {
  color: #bdbdbd;
}

.form-group.is-focused .help-block {
  display: block;
}

.form-group.has-warning .form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-group.has-warning.is-focused .form-control {
  background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#ff5722),
      to(#ff5722)
    ),
    -webkit-gradient(linear, left top, left bottom, from(#d2d2d2), to(#d2d2d2));
  background-image: -webkit-linear-gradient(#ff5722, #ff5722),
    -webkit-linear-gradient(#d2d2d2, #d2d2d2);
  background-image: -o-linear-gradient(#ff5722, #ff5722),
    -o-linear-gradient(#d2d2d2, #d2d2d2);
  background-image: linear-gradient(#ff5722, #ff5722),
    linear-gradient(#d2d2d2, #d2d2d2);
}

.form-group.has-warning .help-block,
.form-group.has-warning label.control-label {
  color: #ff5722;
}

.form-group.has-error .form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-group.has-error .help-block,
.form-group.has-error label.control-label {
  color: #f44336;
}

.form-group.has-success .form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-group.has-success.is-focused .form-control {
  background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#4caf50),
      to(#4caf50)
    ),
    -webkit-gradient(linear, left top, left bottom, from(#d2d2d2), to(#d2d2d2));
  background-image: -webkit-linear-gradient(#4caf50, #4caf50),
    -webkit-linear-gradient(#d2d2d2, #d2d2d2);
  background-image: -o-linear-gradient(#4caf50, #4caf50),
    -o-linear-gradient(#d2d2d2, #d2d2d2);
  background-image: linear-gradient(#4caf50, #4caf50),
    linear-gradient(#d2d2d2, #d2d2d2);
}

.form-group.has-success .help-block,
.form-group.has-success label.control-label {
  color: #4caf50;
}

.form-group.has-info .form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-group.has-info.is-focused .form-control {
  background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#03a9f4),
      to(#03a9f4)
    ),
    -webkit-gradient(linear, left top, left bottom, from(#d2d2d2), to(#d2d2d2));
  background-image: -webkit-linear-gradient(#03a9f4, #03a9f4),
    -webkit-linear-gradient(#d2d2d2, #d2d2d2);
  background-image: -o-linear-gradient(#03a9f4, #03a9f4),
    -o-linear-gradient(#d2d2d2, #d2d2d2);
  background-image: linear-gradient(#03a9f4, #03a9f4),
    linear-gradient(#d2d2d2, #d2d2d2);
}

.form-group.has-info .help-block,
.form-group.has-info label.control-label {
  color: #03a9f4;
}

.form-group textarea {
  resize: none;
}

.form-group textarea ~ .form-control-highlight {
  margin-top: -11px;
}

.form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-group select ~ .material-input:after {
  display: none;
}

.form-control {
  margin-bottom: 7px;
}

.form-control::-moz-placeholder {
  font-size: 16px;
  line-height: 1.42857143;
  color: #bdbdbd;
  font-weight: 400;
}

.form-control:-ms-input-placeholder {
  font-size: 16px;
  line-height: 1.42857143;
  color: #bdbdbd;
  font-weight: 400;
}

.form-control::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 1.42857143;
  color: #bdbdbd;
  font-weight: 400;
}
.checkbox label,
.radio label,
label {
  font-size: 16px;
  line-height: 1.42857143;
  color: #bdbdbd;
  font-weight: 400;
}

label.control-label {
  font-size: 12px;
  line-height: 1.07142857;
  font-weight: 400;
  margin: 16px 0 0 0;
}

.help-block {
  margin-top: 0;
  font-size: 12px;
}

.form-group {
  padding-bottom: 7px;
  margin: 28px 0 0 0;
}

.form-group .form-control {
  margin-bottom: 7px;
}

.form-group .form-control::-moz-placeholder {
  font-size: 16px;
  line-height: 1.42857143;
  color: #bdbdbd;
  font-weight: 400;
}

.form-group .form-control:-ms-input-placeholder {
  font-size: 16px;
  line-height: 1.42857143;
  color: #bdbdbd;
  font-weight: 400;
}

.form-group .form-control::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 1.42857143;
  color: #bdbdbd;
  font-weight: 400;
}

.form-group .checkbox label,
.form-group .radio label,
.form-group label {
  font-size: 16px;
  line-height: 1.42857143;
  color: #bdbdbd;
  font-weight: 400;
}

.form-group label.control-label {
  font-size: 12px;
  line-height: 1.07142857;
  font-weight: 400;
  margin: 16px 0 0 0;
}

.form-group .help-block {
  margin-top: 0;
  font-size: 12px;
}

.form-group.label-floating label.control-label,
.form-group.label-placeholder label.control-label {
  top: -7px;
  font-size: 16px;
  line-height: 1.42857143;
}

.form-group.label-floating.is-focused label.control-label,
.form-group.label-floating:not(.is-empty) label.control-label,
.form-group.label-static label.control-label {
  top: -30px;
  left: 0;
  font-size: 12px;
  line-height: 1.07142857;
}

.form-group.label-floating
  input.form-control:-webkit-autofill
  ~ label.control-label
  label.control-label {
  top: -30px;
  left: 0;
  font-size: 12px;
  line-height: 1.07142857;
}

.form-group.form-group-sm {
  padding-bottom: 3px;
  margin: 21px 0 0 0;
}

.form-group.form-group-sm .form-control {
  margin-bottom: 3px;
}

.form-group.form-group-sm .form-control::-moz-placeholder {
  font-size: 11px;
  line-height: 1.5;
  color: #bdbdbd;
  font-weight: 400;
}

.form-group.form-group-sm .form-control:-ms-input-placeholder {
  font-size: 11px;
  line-height: 1.5;
  color: #bdbdbd;
  font-weight: 400;
}

.form-group.form-group-sm .form-control::-webkit-input-placeholder {
  font-size: 11px;
  line-height: 1.5;
  color: #bdbdbd;
  font-weight: 400;
}

.form-group.form-group-sm .checkbox label,
.form-group.form-group-sm .radio label,
.form-group.form-group-sm label {
  font-size: 11px;
  line-height: 1.5;
  color: #bdbdbd;
  font-weight: 400;
}

.form-group.form-group-sm label.control-label {
  font-size: 9px;
  line-height: 1.125;
  font-weight: 400;
  margin: 16px 0 0 0;
}

.form-group.form-group-sm .help-block {
  margin-top: 0;
  font-size: 9px;
}

.form-group.form-group-sm.label-floating label.control-label,
.form-group.form-group-sm.label-placeholder label.control-label {
  top: -11px;
  font-size: 11px;
  line-height: 1.5;
}

.form-group.form-group-sm.label-floating.is-focused label.control-label,
.form-group.form-group-sm.label-floating:not(.is-empty) label.control-label,
.form-group.form-group-sm.label-static label.control-label {
  top: -25px;
  left: 0;
  font-size: 1.8rem;
  line-height: 1.125;
}

.form-group.form-group-sm.label-floating
  input.form-control:-webkit-autofill
  ~ label.control-label
  label.control-label {
  top: -25px;
  left: 0;
  font-size: 1.8rem;
  line-height: 1.125;
}

.form-group.form-group-lg {
  padding-bottom: 9px;
  margin: 30px 0 0 0;
}

.form-group.form-group-lg .form-control {
  margin-bottom: 9px;
}

.form-group.form-group-lg .form-control::-moz-placeholder {
  font-size: 18px;
  line-height: 1.3333333;
  color: #bdbdbd;
  font-weight: 400;
}

.form-group.form-group-lg .form-control:-ms-input-placeholder {
  font-size: 18px;
  line-height: 1.3333333;
  color: #bdbdbd;
  font-weight: 400;
}

.form-group.form-group-lg .form-control::-webkit-input-placeholder {
  font-size: 18px;
  line-height: 1.3333333;
  color: #bdbdbd;
  font-weight: 400;
}

.form-group.form-group-lg .checkbox label,
.form-group.form-group-lg .radio label,
.form-group.form-group-lg label {
  font-size: 18px;
  line-height: 1.3333333;
  color: #bdbdbd;
  font-weight: 400;
}

.form-group.form-group-lg label.control-label {
  font-size: 14px;
  line-height: 0.99999998;
  font-weight: 400;
  margin: 16px 0 0 0;
}

.form-group.form-group-lg .help-block {
  margin-top: 0;
  font-size: 14px;
}

.form-group.form-group-lg.label-floating label.control-label,
.form-group.form-group-lg.label-placeholder label.control-label {
  top: -5px;
  font-size: 18px;
  line-height: 1.3333333;
}

.form-group.form-group-lg.label-floating.is-focused label.control-label,
.form-group.form-group-lg.label-floating:not(.is-empty) label.control-label,
.form-group.form-group-lg.label-static label.control-label {
  top: -32px;
  left: 0;
  font-size: 1.8rem;
  line-height: 0.99999998;
}

.form-group.form-group-lg.label-floating
  input.form-control:-webkit-autofill
  ~ label.control-label
  label.control-label {
  top: -32px;
  left: 0;
  font-size: 14px;
  line-height: 0.99999998;
}

select.form-control {
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}

.form-group.is-focused select.form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #d2d2d2;
}

.form-group.is-focused select.form-control[multiple],
select.form-control[multiple] {
  height: 85px;
}

.input-group-btn .btn {
  margin: 0 0 7px 0;
}

.form-group.form-group-sm .input-group-btn .btn {
  margin: 0 0 3px 0;
}

.form-group.form-group-lg .input-group-btn .btn {
  margin: 0 0 9px 0;
}

.input-group .input-group-btn {
  padding: 0 12px;
}

.input-group .input-group-addon {
  border: 0;
  background: 0 0;
}

.form-group input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.contact-widget-section .single-contact-widget {
  background: #f9f9f9;
  padding: 20px 25px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.26);
  height: 260px;
  margin-top: 25px;
  transition: all 0.3s ease-in-out;
}

.contact-widget-section .single-contact-widget i {
  font-size: 75px;
}

.contact-widget-section .single-contact-widget h3 {
  font-size: 20px;
  color: #333;
  font-weight: 700;
  padding-bottom: 10px;
}

.contact-widget-section .single-contact-widget p {
  line-height: 2rem;
}

.contact-widget-section .single-contact-widget:hover {
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.46);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

#contactForm {
  margin-top: -10px;
}

#contactForm .form-group label.control-label {
  color: #4d4d4d;
  font-size: 1.8rem;
}

#contactForm .form-control {
  font-weight: 500;
  height: auto;
}
</style>
