<template>
  <div class="home">
    <section class="hero text-white">
      <template>
        <hooper
          class="h-100"
          :wheelControl="false"
          :autoPlay="true"
          :playSpeed="5000"
          :transition="1000"
          :infiniteScroll="true"
          :hoverPause="false"
        >
          <slide class="hero-slide-1">
            <div class="px-5 h-100 container d-flex align-items-center">
              <div class="">
                <h1 class="display-1 fw-bold v-text">Shark Cage Diving</h1>
                <h3 class="v-text pb-3">The Ultimate Cape Town Experience</h3>
                <a
                  href="https://wa.me/+27823792293"
                  target="_blank"
                  class="btn btn-lg btn-red p"
                  >Book Your Spot Now</a
                >
              </div>
            </div>
          </slide>
          <slide class="hero-slide-2">
            <div class="px-5 h-100 container d-flex align-items-center">
              <div class="">
                <h1 class="display-1 fw-bold v-text">Bungee Jumping</h1>
                <h3 class="v-text pb-3">The Ultimate Cape Town Experience</h3>
                <a
                  href="https://wa.me/+27823792293"
                  target="_blank"
                  class="btn btn-lg btn-red p"
                  >Book Your Spot Now</a
                >
              </div>
            </div>
          </slide>
          <slide class="hero-slide-3">
            <div class="px-5 h-100 container d-flex align-items-center">
              <div class="">
                <h1 class="display-1 fw-bold v-text">Quad Biking</h1>
                <h3 class="v-text pb-3">The Ultimate Cape Town Experience</h3>
                <a
                  href="https://wa.me/+27823792293"
                  target="_blank"
                  class="btn btn-lg btn-red p"
                  >Book Your Spot Now</a
                >
              </div>
            </div>
          </slide>
          <slide class="hero-slide-4">
            <div class="px-5 h-100 container d-flex align-items-center">
              <div class="">
                <h1 class="display-1 fw-bold v-text">Safari</h1>
                <h3 class="v-text pb-3">The Ultimate Cape Town Experience</h3>
                <a
                  href="https://wa.me/+27823792293"
                  target="_blank"
                  class="btn btn-lg btn-red p"
                  >Book Your Spot Now</a
                >
              </div>
            </div>
          </slide>
          <slide class="hero-slide-5">
            <div class="px-5 h-100 container d-flex align-items-center">
              <div class="">
                <h1 class="display-1 fw-bold v-text">Adventure</h1>
                <h3 class="v-text pb-3">The Ultimate Cape Town Experience</h3>
                <a
                  href="https://wa.me/+27823792293"
                  target="_blank"
                  class="btn btn-lg btn-red p"
                  >Book Your Spot Now</a
                >
              </div>
            </div>
          </slide>

          ...
          <hooper-navigation slot="hooper-addons"></hooper-navigation>
          <hooper-pagination slot="hooper-addons"></hooper-pagination>
        </hooper>
      </template>
    </section>

    <main class="container">
      <section class="card-section-body">
        <div class="container1">
          <div class="card">
            <div class="imgBx">
              <img src="../assets/quad-1.webp" class="rounded" alt="" />
              <h2 class="text-center v-text card-header-bottom h4 pt-3">
                Adrenaline
              </h2>
            </div>
            <div class="content pt-5 w-100">
              <h2 class="v-text">
                We Do<br />
                <span class="text-red h4"> Adrenaline</span>
              </h2>
              <router-link to="/adrenaline" class="btn btn-primary text-white p"
                >Show Me</router-link
              >
            </div>
          </div>
          <div class="card">
            <div class="imgBx">
              <img src="../assets/aquila-1.webp" class="rounded" alt="" />
              <h2 class="text-center v-text card-header-bottom h4 pt-3">
                Safari
              </h2>
            </div>
            <div class="content">
              <h2 class="v-text">
                We Do<br />
                <span class="text-red h4">Safari</span>
              </h2>
              <router-link to="safari" class="btn btn-primary text-white p"
                >Show Me</router-link
              >
            </div>
          </div>
          <div class="card">
            <div class="imgBx">
              <img src="../assets/wine-3.webp" class="rounded" alt="" />
              <h2 class="text-center v-text card-header-bottom h4 pt-3">
                Wine
              </h2>
            </div>
            <div class="content">
              <h2 class="v-text">
                We Do<br />
                <span class="text-red h4">Wine</span>
              </h2>
              <router-link to="/tours" class="btn btn-primary text-white p"
                >Show Me</router-link
              >
            </div>
          </div>
          <div class="card">
            <div class="imgBx">
              <img src="../assets/shark-3.webp" class="rounded" alt="" />
              <h2 class="text-center v-text card-header-bottom h4 pt-3">
                Adventure
              </h2>
            </div>
            <div class="content">
              <h2 class="v-text">
                We Do<br />
                <span class="text-red h4">Adventure</span>
              </h2>
              <router-link to="combo" class="btn btn-primary text-white p"
                >Show Me</router-link
              >
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="row">
          <div class="col-md-6">
            <h2 class="pb-5 text-red v-text">Welcome to Adventure Cape Town</h2>
            <p>
              From the natural wonder that is Table Mountain to awe-inspiring
              desert landscapes and Precambrian limestone caves.
            </p>
            <p>
              Home to mountains, oceans, beautiful nature reserves and
              vineyards, Cape Town is the ideal spot for some of the world’s
              most unique and popular adventure activities. Discover a world of
              unique experiences and adrenaline fuelled adventures, and be
              inspired by the diversity of culture, cuisine, wine and wildlife.
              From tours and tastings at the most elegant wine farms, to
              adrenaline pumping quad bike rides in majestic, powdery dunes - we
              have you covered!
            </p>
            <p>
              Visit the Big 5, go sand boarding, paraglide over Cape Town for
              the best views imaginable, explore some of the best craft beer and
              gin distilleries, go shark cage diving, hike to natural pools
              where you can cliff jump into crystal waters, horseback ride along
              an endless coast, walk with lions or feed elephants, the list goes
              on and on and on. With almost two decades experience, Adventure
              Cape Town will ensure your craving for adventure is satisfied!
            </p>
            <p>Why go with the rest when you can go with the best ;)</p>
          </div>
          <div class="col-md-6 d-flex align-items-center">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/_V_wr1xh5ag"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay;  picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";

export default {
  components: {
    Hooper,
    Slide,
    HooperNavigation,
  },
  data() {
    return {};
  },
};
</script>
