<template>
  <div class="botlierskop">
    <section class="hero text-white d-flex align-items-center">
      <div class="px-5 container">
        <h1 class="display-1 fw-bold v-text">We Do Wild</h1>
        <h3 class="v-text pb-3">
          The Ultimate Botlierskop Private Game Reserve Experience
        </h3>
        <a
          href="#"
          class="
            btn btn-lg btn-secondary
            fw-bold
            border-white
            bg-white
            text-dark
            p
          "
          >Book Your Spot Now</a
        >
      </div>
    </section>

    <main class="container mt-5">
      <Botlierskop></Botlierskop>
    </main>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>
